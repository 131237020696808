import React, { Component, Fragment } from 'react'
import { Image, Dimmer, Loader } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import {
    selectLocale,
    fetchLocales,
    selectGlobalOrder,
    switchToInit,
    fetchUserInfo
} from '../../../actions'

import { handleSelectSchedule } from './handleSelectSchedule'

import ErrorBoundary from '../../layout/ErrorBoundary'
import ErrorMessage from '../../ui/ErrorMessage'
import { mapStateToProps } from './mapStateToProps'
import { RetraitBoutiqueDetail } from './RetraitBoutiqueDetail'

import NuageLeft from '../../../assets/nuage_left.svg'
import NuageRight from '../../../assets/nuage_right.svg'
import ArrowRight from '../../../assets/arrow-right.svg'

import Styles from './styles.module.css'

/**
 * ChooseSchedule component allows the user to choose the locale through which he wants to pick
 * up his order.
 * A user who opens the app for the first time will go to Orders page, and then since no locale
 * has been chosen, he will get  redirected here.
 */

function differenceBetweenObjects(base, object) {
    let diff = false
    if (typeof object == 'object' && typeof base == 'object') {
        for (let k in object) {
            if (typeof object[k] != 'object') {
                if (base[k] != object[k]) {
                    if (diff == false) {
                        diff = {}
                    }
                    diff[k] = object[k]
                }
            } else {
                let subDiff = differenceBetweenObjects(base[k], object[k])
                if (subDiff !== false) {
                    if (diff === false) {
                        diff = {}
                    }
                    diff[k] = subDiff
                }
            }
        }
    }
    if (typeof base == 'object') {
        for (let k in base) {
            if (
                typeof object != 'object' ||
                !Object.hasOwnProperty.bind(object)(k)
            ) {
                if (diff === false) {
                    diff = {}
                }
                if (typeof base[k] == 'object') {
                    let subDiff = differenceBetweenObjects(base[k])
                    diff[k] = subDiff
                } else {
                    diff[k] = null
                }
            }
        }
    }
    return diff
}

export class ChooseScheduleBase extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showWarning: false,
            redirectGlobale: props.redirectGlobale || false,
            schedule: {},
            headerDiv: document.querySelector("header"),
        }

        this.handleCloseWarning = this.handleCloseWarning.bind(this)
        this.handleLocaleClick = this.handleLocaleClick.bind(this)
        this.handleLocaleUnclick = this.handleLocaleUnclick.bind(this)
        this.handleRetry = this.handleRetry.bind(this)
        this.handleSelectSchedule = handleSelectSchedule.bind(this)
    }

    async componentDidMount() {
        const { currentUser } = this.props

        // Fetching locales
        if (currentUser && currentUser.loggedin) {
            setTimeout(() => {
                this.props.fetchLocales(currentUser._id)
                this.props.fetchUserInfo()
            }, 200);
        } else {
            this.props.fetchLocales()
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // don't re-render when event emitter resets...
        const { eventEmitter, ...diffProps } = differenceBetweenObjects(
            this.props,
            nextProps
        )
        const diffState = differenceBetweenObjects(
            this.state || {},
            nextState || {}
        )

        return (
            Object.keys(diffProps).length > 0 ||
            Object.keys(diffState).length > 0
        )
    }

    handleCloseWarning() {
        this.setState({ showWarning: false })
    }

    handleRetry() {
        const { currentUser } = this.props
        this.props.fetchLocales(currentUser._id)
    }

    handleLocaleClick(locale) {
        this.props.eventEmitter.emit('boutique-click', locale)
    }

    handleLocaleUnclick() {
        this.props.eventEmitter.emit('boutique-unclick')
    }

    render() {
        if (this.props.error) {
            return <ErrorMessage handleRetry={this.handleRetry} />
        }

        if (!this.props.localesReady || this.props.localesLoading) {
            return (
                <Dimmer
                    active
                    inverted
                >
                    <Loader size="large">Chargement des magasins</Loader>
                </Dimmer>
            )
        }
        return (
            <Fragment>
                {this.state.showWarning &&
                    this.props.location.pathname !==
                    '/verification-du-panier' && (
                        <Redirect
                            data-test-id="redirect"
                            to={{
                                pathname: '/verification-du-panier',
                                setState: this.setState,
                                handleCloseWarning: this.handleCloseWarning,
                                showWarning: this.state.showWarning,
                                locationSelect: {
                                    scheduleSelect: this.state.schedule,
                                    localSelect: this.state.newLocale,
                                },
                            }}
                        />
                    )}
                <div className={Styles.Container}>
                    <div className={Styles.HeaderContainer}>
                        <Image
                            src={NuageLeft}
                            alt=""
                        />
                        <div className={Styles.innerTextContainer}>
                            <div className={Styles.fontWeightBold}>
                                1. Je choisis mon point de retrait
                            </div>
                            <Image
                                className={Styles.image}
                                src={ArrowRight}
                                alt=""
                            />
                            <div className={Styles.fontWeightBold}>
                                2. Je fais mon marché
                            </div>
                            <Image
                                className={Styles.image}
                                src={ArrowRight}
                                alt=""
                            />
                            <div className={Styles.fontWeightBold}>
                                3. Je déguste mes produits
                            </div>
                        </div>

                        <Image
                            src={NuageRight}
                            alt=""
                        />
                    </div>

                    <div className={Styles.MainContainer}>
                        <RetraitBoutiqueDetail
                            locales={this.props.locales}
                            eventEmitter={this.props.eventEmitter}
                            handleLocaleClick={this.handleLocaleClick}
                            handleLocaleUnclick={this.handleLocaleUnclick}
                            handleSelectSchedule={this.handleSelectSchedule}
                            currentUser={this.props.currentUser}
                        />
                    </div>
                </div>
            </Fragment>
        )
    }
}

ChooseScheduleBase.propTypes = {
    localesReady: PropTypes.bool,
    fetchLocales: PropTypes.func.isRequired,
    locales: PropTypes.array,
    eventEmitter: PropTypes.object,
    currentUser: PropTypes.object,
    error: PropTypes.bool,
    selectLocale: PropTypes.func.isRequired,
}

const ChooseSchedule = props => (
    <ErrorBoundary page="choose-locale">
        <ChooseScheduleBase {...props} />
    </ErrorBoundary>
)

const mapDispatchToProps = dispatch => {
    return {
        fetchLocales: params => dispatch(fetchLocales(params)),
        selectLocale: params => dispatch(selectLocale(params)),
        selectGlobalOrder: params => dispatch(selectGlobalOrder(params)),
        switchToInit: params => dispatch(switchToInit(params)),
        fetchUserInfo: () => dispatch(fetchUserInfo()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseSchedule)
