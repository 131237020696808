import { useState, useRef, useEffect } from 'react'
import { Icon } from 'semantic-ui-react'
import CardPartenaire from './CardPartenaire'
import Styles from './styles.module.css'
import { getCenter } from './TheMap/helpers'
import PlacesAutocomplete from './TheMap/PlaceAutocomplete'
import StaticMap from './StaticMap'
import TheMap from './TheMap'

import crossWhite from '../../../../assets/cross-white.svg'
import newLocale from '../../../../assets/new-locale.png'
import closeIcon from '../../../../assets/closeCross.svg'
import mapIcon_fonce from '../../../../assets/map_fonce.svg'
import mapIcon_clair from '../../../../assets/map_clair.svg'
import livdomIcon_fonce from '../../../../assets/livdom_fonce.svg'
import livdomIcon_clair from '../../../../assets/livdom_clair.svg'
import InfoModal from '../../../ui/InfoModal'

const pictures = [crossWhite, closeIcon, mapIcon_clair, mapIcon_fonce, livdomIcon_clair, livdomIcon_fonce]

export const RetraitBoutiqueDetail = ({
    locales,
    eventEmitter,
    handleLocaleUnclick,
    handleLocaleClick,
    handleSelectSchedule,
    currentUser,
}) => {
    const semaine = { 0: 'Dimanche', 1: 'Lundi', 2: 'Mardi', 3: 'Mercredi', 4: 'Jeudi', 5: 'Vendredi', 6: 'Samedi' }

    // Creating states
    // Locale selected whether in the map or in the list
    const [selectedLocaleList, setSelectedLocaleList] = useState(null)
    const [selectedLocaleMap, setSelectedLocaleMap] = useState(null)

    // Different iterations of the locales object
    const [filteredLocales, setFilteredLocales] = useState(locales && locales.filter(l => l.code !== 'DOM'))
    const [LocatedLocales, setLocatedLocales] = useState(null)
    const [newLocatedLocales, setNewLocatedLocales] = useState(null)
    const [livdomLocale, setLivdomLocale] = useState(locales && locales.find(l => l.code === 'DOM'))

    const [dayFilter, setDayFilter] = useState(null)
    const [salesDays, setSalesDays] = useState([])

    const [mapCenter, setMapCenter] = useState(getCenter(locales))
    const [selectedLatLng, setSelectedLatLng] = useState(null)

    const [selectedTab, setSelectedTab] = useState('map')
    const [isHover, setIsHover] = useState(null)

    const [popupVisible, setPopupVisible] = useState(false)
    const [addressError, setAddressError] = useState(false)
    const [openPanel, setOpenPanel] = useState(
        new Array(locales.length).fill(false)
    )
    const [autocompleteType, setAutocompleteType] = useState('address')
    const [searchedLocale, setSearchedLocale] = useState('')

    // Creating refs
    const mapRef = useRef()
    const staticRef = useRef()
    const cardListRef = useRef(null)

    // Helper function to calculate distances
    function calculateDistance(latlng1, latlng2) {
        const { lat: lat1, lng: lng1 } = latlng1
        const { lat: lat2, lng: lng2 } = latlng2
        const toRad = angle => (Math.PI / 180) * angle
        const R = 6371

        const dLat = toRad(lat2 - lat1)
        const dLng = toRad(lng2 - lng1)

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRad(lat1)) *
            Math.cos(toRad(lat2)) *
            Math.sin(dLng / 2) *
            Math.sin(dLng / 2)
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        const distance = R * c

        return distance
    }

    function filterDays(day) {
        if (day !== dayFilter) {
            setDayFilter(day)
            let tempFilteredLocales = []
            locales.forEach(l => {
                for (let i = 0; i < l.distributions.length; i++) {
                    if (l.distributions[i].day === day && l.code !== 'DOM') {
                        tempFilteredLocales.push(l)
                        break
                    }
                }
            })
            setFilteredLocales(tempFilteredLocales)
        }
        else {
            setSelectedLocaleMap(null)
            setSelectedLocaleList(null)
            setDayFilter(null)
            setFilteredLocales(locales.filter(l => l.code !== 'DOM'))

        }
        setOpenPanel(new Array(locales.length).fill(false))
    }

    function geolocalizeUser() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    let lat = position.coords.latitude
                    let lng = position.coords.longitude

                    let nearestLocale = null
                    let minDistance = Infinity
                    for (const locale of LocatedLocales) {
                        const distance = calculateDistance(
                            { lat, lng },
                            locale.geolocation
                        )
                        if (distance < minDistance) {
                            minDistance = distance
                            nearestLocale = locale
                        }
                    }

                    if (nearestLocale && mapRef && mapRef.current) {
                        mapRef.current.panTo({ lat, lng })
                        mapRef.current.setZoom(13)
                    }

                    setSelectedLatLng({ lat: lat, lng: lng })
                }
            )
        }
    }

    // Make pop-up appear only once
    useEffect(() => {
        // Handle popup
        let pop_status = localStorage.getItem('pop_status');
        if (pop_status !== 'seen') {
            setPopupVisible(true);
            localStorage.setItem('pop_status', 'seen');
        }
        else {
            setPopupVisible(false)
        }

        // Preload images
        pictures.forEach(picture => {
            const img = new Image();
            img.src = picture
            window[picture] = img
        })
    }, [])

    // Create LocatedLocales array
    useEffect(() => {
        // Tag new locales 
        let today = new Date()
        filteredLocales.forEach(l => {
            if (l.createdAt) {
                let createdAt = new Date(l.createdAt)
                if (((today - createdAt) / 1000 / 60 / 60 / 24) < 60) {
                    l['new'] = true
                }
                else {
                    l['new'] = false
                }
            }
            else {
                l['new'] = false
            }
        })

        // Create LocatedLocales temp array
        let tempLocatedLocales
        if (selectedLatLng !== null) {
            tempLocatedLocales = filteredLocales.slice(0).sort((locale1, locale2) => {
                const distance1 = calculateDistance(
                    locale1.geolocation,
                    selectedLatLng
                )
                const distance2 = calculateDistance(
                    locale2.geolocation,
                    selectedLatLng
                )
                return distance1 - distance2
            })
        } else {
            tempLocatedLocales = filteredLocales.slice(0).sort((locale1, locale2) => {
                const salesCount1 = locale1.schedules.length
                const salesCount2 = locale2.schedules.length
                return Number(locale2.new) - Number(locale1.new) || salesCount2 - salesCount1
            })
        }

        setLocatedLocales(tempLocatedLocales)
        setNewLocatedLocales(tempLocatedLocales)

    }, [selectedLatLng, filteredLocales, dayFilter])

    useEffect(() => {
        if (newLocatedLocales) {
            setNewLocatedLocales(LocatedLocales.filter(l => l.name.toLowerCase().includes(searchedLocale.toLowerCase())))
        }
    }, [searchedLocale])

    useEffect(() => {
        let daysArray = []
        locales && locales.forEach(l => {
            l.distributions.forEach(d => {
                if (!daysArray.includes(d.day)) {
                    daysArray.push(d.day)
                }
            })
        })
        daysArray.sort(function (a, b) { return a - b });
        setSalesDays(daysArray)
    }, [locales])

    return (
        <>
            <InfoModal
                show={!!addressError}
                onClose={() => setAddressError(false)}
                isError={true}
                header={"Merci de vérifier cette adresse"}
                body={
                    "Cette adresse n'est pas reconnue comme une adresse de livraison valide"
                }
            />

            {popupVisible && window.innerWidth < 1000 && (
                <div className={Styles.overlayBackground}></div>
            )}

            {popupVisible && window.innerWidth < 1000 && (
                <div className={Styles.popupContainer}>
                    <img
                        src={closeIcon}
                        className={Styles.closeIcon}
                        onClick={() => setPopupVisible(false)}
                        alt="Fermer la popup"
                    />

                    <div className={Styles.popupContent}>
                        <div className={Styles.popupText}>
                            1. Je choisis mon point de retrait
                        </div>

                        <div className={Styles.popupText}>
                            2. Je fais mon marché
                        </div>
                        <div className={Styles.lastPopupText}>
                            3. Je déguste mes produits
                        </div>
                    </div>
                </div>
            )}

            <div className={Styles.mobileParallax}>
                <p className={Styles.pageTitle}>Pour voir les produits, choisissez un lieu et un jour de retrait 👇</p>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <div
                        className={selectedTab === 'map' ? Styles.buttonSelected : Styles.button}
                        onClick={() => {
                            setSelectedLocaleList(null)
                            setSelectedLocaleMap(null)
                            setSelectedTab('map')
                            setAutocompleteType('address')
                        }}
                    >
                        <img name="map" src={selectedTab === 'map' ? mapIcon_clair : mapIcon_fonce} style={window.innerWidth > 1024 ? { height: '20px', marginRight: '10px' } : selectedTab === 'map' ? { height: '20px', marginRight: '10px' } : { height: '20px' }} />
                        {window.innerWidth > 1024 ? 'Sélectionner mon jour et mon lieu de retrait' : selectedTab === 'map' ? 'Choisir ma vente' : ''}
                    </div>
                    <div
                        className={selectedTab === 'list' ? Styles.buttonSelected : Styles.button}
                        onClick={() => {
                            setSelectedLocaleList(null)
                            setSelectedLocaleMap(null)
                            setSelectedTab('list')
                            setAutocompleteType('boutique')
                        }}
                    >
                        <Icon name="list" style={selectedTab === 'list' ? { color: 'white', marginRight: '10px' } : window.innerWidth > 1024 ? { color: '#075244', marginRight: '10px' } : { color: '#075244', fontSize: '1.1em !important' }} />
                        {window.innerWidth > 1024 ? 'Voir la liste de tous les lieux de retrait' : selectedTab === 'list' ? 'Lieux de retrait' : ''}
                    </div>
                    {/* ====> CHANGE TO ENABLE LIVDOM FOR ALL <===== */}
                    {currentUser &&
                        ['raphael@kelbongoo.com',
                            'cecile@kelbongoo.com',
                            'adrien@kelbongoo.com',
                            'mauve.hadrien@gmail.com',
                            'pauline@kelbongoo.com',
                            'samuel@kelbongoo.com'].includes(currentUser?.email) &&
                        <div
                            className={selectedTab === 'livdom' ? Styles.buttonSelected : Styles.button}
                            onClick={() => {
                                setSelectedLocaleList(null)
                                setSelectedLocaleMap(null)
                                setSelectedTab('livdom')
                                setAutocompleteType(null)
                            }}
                        >
                            <img name="livdom" alt='livdom' src={selectedTab === 'livdom' ? livdomIcon_clair : livdomIcon_fonce} style={window.innerWidth > 1024 ? { height: '20px', marginRight: '10px' } : selectedTab === 'livdom' ? { height: '20px', marginRight: '10px' } : { height: '20px' }} />
                            {window.innerWidth > 1024 ? 'Livraison à domicile' : selectedTab === 'livdom' ? "Livraison à domicile" : ''}
                            <img className={Styles.livdomNew} alt="nouveau" src={newLocale} />
                        </div>
                    }
                </div>
                {autocompleteType &&
                    <div style={window.innerWidth > 1024 ? { position: 'static' } : { position: 'sticky', top: '70px', left: '0px', zIndex: '1101' }}>
                        <PlacesAutocomplete
                            setIsHover={setIsHover}
                            setSearchedLocale={setSearchedLocale}
                            searchedLocale={searchedLocale}
                            type={autocompleteType}
                            geolocalizeUser={geolocalizeUser}
                            mapRef={mapRef}
                            isPositionAbsolute={false}
                            setSelectedLatLng={setSelectedLatLng}
                            locales={newLocatedLocales}
                            cardListRef={cardListRef}
                        />
                        <div className={Styles.datePickerContainer}>
                            <p style={{ margin: '0 10px 0 0', fontWeight: '500', color: 'white', whiteSpace: 'nowrap' }}>Livré :</p>
                            {salesDays.map(day => {
                                return (
                                    <div onClick={() => filterDays(day)} className={dayFilter === day ? Styles.dayButtonSelected : Styles.dayButton}>
                                        {semaine[day]}
                                        {day === dayFilter && <img className={Styles.closeDayFilter} src={crossWhite} />}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                }

                {(selectedTab === 'map' || selectedTab === 'livdom') && (
                    <div className={Styles.TheMap}>
                        <TheMap
                            setAddressError={setAddressError}
                            selectedTab={selectedTab}
                            livdomLocale={livdomLocale}
                            isHover={isHover}
                            setIsHover={setIsHover}
                            mapRef={mapRef}
                            setSelectedLocaleMap={setSelectedLocaleMap}
                            selectedLocaleMap={selectedLocaleMap}
                            setSelectedLatLng={setSelectedLatLng}
                            selectedLatLng={selectedLatLng}
                            mapCenter={mapCenter}
                            openPanel={openPanel}
                            setOpenPanel={setOpenPanel}
                            locales={LocatedLocales}
                            currentUser={currentUser}
                            eventEmitter={eventEmitter}
                            handleLocaleClick={handleLocaleClick}
                            setMapCenter={setMapCenter}
                            handleSelect={handleSelectSchedule}
                            handleLocaleUnclick={handleLocaleUnclick}
                        />
                    </div>
                )}

                {selectedTab === 'list' && (
                    <div
                        className={
                            window.innerWidth > 1000
                                ? Styles.Magasins
                                : selectedTab !== 'map'
                                    ? Styles.Magasins
                                    : Styles.MobileMagasins
                        }
                        data-testid="boutiques-list-container"
                    >
                        <div
                            id="card-list-ref"
                            className={Styles.cardList}
                            ref={cardListRef}
                        >
                            {newLocatedLocales && newLocatedLocales.map((locale, index) => {
                                return (
                                    <CardPartenaire
                                        where={'list'}
                                        locale={locale}
                                        selectedLocale={selectedLocaleList}
                                        setSelectedLocale={setSelectedLocaleList}
                                        staticRef={staticRef}
                                        currentUser={currentUser}
                                        handleLocaleClick={handleLocaleClick}
                                        handleLocaleUnclick={handleLocaleUnclick}
                                        eventEmitter={eventEmitter}
                                        handleNavigate={handleSelectSchedule}
                                        mapRef={mapRef}
                                        localeLength={locales.length}
                                        openPanel={openPanel}
                                        index={index}
                                        setOpenPanel={setOpenPanel}
                                        mapCenter={mapCenter}
                                        newLocatedLocales={newLocatedLocales}
                                        setMapCenter={setMapCenter}
                                    />
                                )
                            })}
                            {(!newLocatedLocales || newLocatedLocales.length === 0) && searchedLocale.length > 0 &&
                                <h5 className={Styles.emptyMention}>{"Oups… ce lieu est inconnu. Revenez à la liste complète :-) !"}</h5>
                            }
                        </div>
                        <StaticMap
                            locales={newLocatedLocales}
                            cardListRef={cardListRef}
                            staticRef={staticRef}
                            center={mapCenter}
                            setCenter={setMapCenter}
                            setSelectedLocale={setSelectedLocaleList}
                        />
                    </div>
                )}
            </div>
        </>
    )
}
