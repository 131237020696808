import { MAX_WIDTH, MIN_WIDTH } from '../../../../../app-constants'
import { Link } from 'react-router-dom'
import { Button, Checkbox } from 'semantic-ui-react'
import Responsive from "../../../../ui/Responsive"

import Styles from './styles.module.css'

export const PersistentCheckoutBar = ({
    currentLocale,
    currentUser,
    handleSubmit,
    door,
    setDoor
}) => (
    <div className={Styles.PersistentCheckoutBar}>
        {currentLocale.code === 'DOM' &&
            <div className={Styles.ConditionsOuter}>
                <Checkbox
                    toggle
                    style={{ fontSize: '13px', textAlign: 'start' }}
                    label="J’autorise Kelbongoo  à déposer ma commande devant ma porte et je déclare que celle-ci est accessible au livreur."
                    name="door"
                    id="door"
                    onChange={(e, data) => {
                        setDoor(data.checked)
                    }}
                    checked={door}
                    data-testid="door"
                />
                <div
                    className={Styles.ConditionsInner}
                    title="livdom-conditions"
                >
                    En cliquant sur "R&eacute;gler ma commande", je
                    d&eacute;clare que les informations de livraison communiqu&eacute;es sont
                    correctes et compl&egrave;tes
                </div>
            </div>
        }

        <div className={Styles.buttonsContainer}>
            <Link to="/cart">
                <Responsive
                    maxWidth={MAX_WIDTH.SM}
                    as={Button}
                    size="medium"
                    content="Je reviens au panier"
                    primary
                    basic
                    icon="left arrow"
                    labelPosition="left"
                    className={`${Styles.ExpandingButton} ${Styles.SmallCartButton}`}
                />
                <Responsive
                    minWidth={MIN_WIDTH.MD}
                    as={Button}
                    size="huge"
                    content="Je reviens au panier"
                    primary
                    basic
                    icon="left arrow"
                    labelPosition="left"
                    className={Styles.ExpandingButton}
                />
            </Link>

            {currentLocale.code !== 'DOM' ?
                <Link to="/checkout/reglement">
                    <Responsive
                        maxWidth={MAX_WIDTH.SM}
                        as={Button}
                        content="Je règle ma commande"
                        primary
                        labelPosition="right"
                        size="medium"
                        icon="right arrow"
                        className={Styles.ExpandingButton}
                    />
                    <Responsive
                        minWidth={MIN_WIDTH.MD}
                        as={Button}
                        content="Je règle ma commande"
                        primary
                        labelPosition="right"
                        size="huge"
                        icon="right arrow"
                        className={Styles.ExpandingButton}
                    />
                </Link>
                :
                <div onClick={() => handleSubmit()}>
                    <Responsive
                        maxWidth={MAX_WIDTH.SM}
                        as={Button}
                        content="Je règle ma commande"
                        primary
                        labelPosition="right"
                        size="medium"
                        icon="right arrow"
                        className={Styles.ExpandingButton}
                    />
                    <Responsive
                        minWidth={MIN_WIDTH.MD}
                        as={Button}
                        content="Je règle ma commande"
                        primary
                        labelPosition="right"
                        size="huge"
                        icon="right arrow"
                        className={Styles.ExpandingButton}
                    />
                </div>
            }
        </div>
    </div>
)
