import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Icon, Container, Header, Grid, Select } from 'semantic-ui-react'
import * as Sentry from '@sentry/browser'

import LinkButton from '../../../../ui/LinkButton'
import Styles from './styles.module.css'

export const CheckoutDeliveryInfo = ({
    livdomDeliveryRange,
    setLivdomDeliveryRange,
    livdomSchedule,
    currentLocale,
    currentUser,
}) => {

    // Pre-select the first option in livdomSchedule if it's not already selected
    useEffect(() => {
        if (!livdomDeliveryRange && livdomSchedule?.length > 0) {
            setLivdomDeliveryRange(livdomSchedule[0].value); // Set the first option as default
        }
    }, [livdomDeliveryRange, livdomSchedule, setLivdomDeliveryRange]);

    // TEMP - in case of invalid address data...
    const getSafeAddress = currentLocale => {
        if (currentLocale && currentLocale.address) {
            return (
                <p style={{ marginTop: '7px' }}>
                    {currentLocale.address.street}
                    <br />
                    {currentLocale.address.zip}{' '}
                    {currentLocale.address.city.toUpperCase()}
                </p>
            )
        } else {
            process.env.REACT_APP_NAMESPACE === 'production' &&
                Sentry.captureException(
                    new Error(
                        'Safe address displayed, invalid locale - ' +
                        JSON.stringify(currentLocale)
                    )
                )
            return <p></p>
        }
    }

    return (
        <Container
            text
            className={Styles.DeliveryContainer}
            data-testid="checkout-delivery-info"
        >
            <Grid columns={2}>
                <Grid.Column>
                    <Header as="a">
                        {currentLocale.code === 'DOM' ? <Icon name="truck" /> : <Icon name="home" />}
                        {currentLocale.code === 'DOM' ? 'Livraison à domicile' : currentLocale.name}
                    </Header>

                    {currentLocale.code === 'DOM' ? (
                        <div style={{ marginTop: '7px' }}>
                            <p>
                                {currentUser.address_street}
                                {currentUser.address_code
                                    ? ` - Code ${currentUser.address_code}`
                                    : ''}
                                <br />
                                {currentUser.address_zip}{' '}
                                {currentUser.address_city.toUpperCase()}
                                <br />
                                {currentUser.address_instructions ? (
                                    <p>
                                        <i>
                                            {currentUser.address_instructions}
                                        </i>
                                    </p>
                                ) : (
                                    ''
                                )}
                            </p>
                            <p>
                                <LinkButton to="/account/details">
                                    <p style={{ color: '#075244' }}><span style={{ textDecoration: 'underline' }}>Changer mon adresse</span>{"   >"}</p>
                                </LinkButton>
                            </p>
                        </div>
                    ) : (
                        getSafeAddress(currentLocale)
                    )}
                </Grid.Column>

                {currentLocale.code !== 'DOM' ?
                    <Grid.Column>
                        <Header as="a">
                            <Icon name="calendar" />
                            {currentLocale.currentSchedule.day}
                        </Header>

                        <p style={{ marginTop: '7px' }}> {currentLocale.currentSchedule.time}</p>
                        {currentLocale.instructions && (
                            <p>{currentLocale.instructions}</p>
                        )}
                    </Grid.Column>
                    :
                    <Grid.Column>
                        <Header as="a">
                            <Icon name="calendar" />
                            {currentLocale.currentSchedule.day}
                        </Header>
                        <div className={Styles.livdomContainer}>
                            <label style={{ textAlign: 'start', marginRight: '10px' }}>Mon créneau de livraison: </label>
                            <Select
                                fluid
                                options={livdomSchedule || []}
                                name="livdomDeliveryRange"
                                value={livdomDeliveryRange || ''}
                                onChange={(e, data) => setLivdomDeliveryRange(data.value)}
                                data-testid="livdomDeliveryRange"
                            />
                        </div>
                    </Grid.Column>
                }
            </Grid>
        </Container>
    )
}

CheckoutDeliveryInfo.propTypes = {
    currentGlobalOrder: PropTypes.object,
    currentLocale: PropTypes.object,
    currentUser: PropTypes.object,
    livdomDeliveryRange: PropTypes.any,
    setLivdomDeliveryRange: PropTypes.func,
    livdomSchedule: PropTypes.array,
}
