export const prerequestHook =
    ({ paymentType, paymentToken, livdomFee }) =>
        ({ getState }) => {
            const { cart, currentGlobalOrder, order, currentLocale } = getState()

            let items = cart.list.map(l => ({
                quantity: cart.items[l],
                producerproduct_id: l,
            }))

            return {
                body: {
                    items,
                    livdomFee,
                    globalorder_id: currentGlobalOrder._id,
                    orderreservation_id: order.id,
                    locale: currentLocale.code,
                    paymentType,
                    paymentToken,
                },
            }
        }
