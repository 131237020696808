import { wrapClientApiCall } from '../../../utils'
import { prerequestHook } from './prerequestHook'
import { successCallback } from './successCallback'
import { failureCallback } from "./failureCallback";

export const confirmOrder = ({ paymentType, paymentToken, livdomFee }) =>
    wrapClientApiCall({
        actionRoot: 'CONFIRM_ORDER',
        method: 'POST',
        url: `api/order-confirmation`,
        hasJsonResponse: true,
        prerequestHook: prerequestHook({ paymentType, paymentToken, livdomFee }),
        failureCallback,
        successCallback,
    })
