import { useState, useEffect } from 'react'
import Styles from './styles.module.css'
import ChevronLeft from '../../../../../assets/chevron-left.svg'
import ChevronRight from '../../../../../assets/chevron-right.svg'
import { Image } from 'semantic-ui-react'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import Checkbox from '@mui/material/Checkbox'

const semaine = { 0: 'Dimanche', 1: 'Lundi', 2: 'Mardi', 3: 'Mercredi', 4: 'Jeudi', 5: 'Vendredi', 6: 'Samedi' }
const mois = { 0: 'Janvier', 1: 'Février', 2: "Mars", 3: "Avril", 4: "Mai", 5: "Juin", 6: "Juillet", 7: "Août", 8: "Septembre", 9: "Octobre", 10: "Novembre", 11: "Décembre" }

const DateSlider = ({ showHours = true, localeSchedule, locale, setSelectedLocale, selectedLocale, handleNavigate, zoomInLocaleStaticMap }) => {

    const [sliderSchedule, setSliderSchedule] = useState([])
    const [sliderHolidaySchedule, setSliderHolidaySchedule] = useState([])
    const [startIndex, setStartIndex] = useState(0)
    const [selectedTimeSlot, setSelectedTimeSlot] = useState('')
    const [startMarket, setStartMarket] = useState(false)
    const [openSubPanel, setOpenSubPanel] = useState(true)
    const [scheduleSelected, setScheduleSelected] = useState(null)

    const handlePrev = () => {
        if (startIndex > 0) {
            setStartIndex(startIndex - 3)
        }
    }

    const handleNext = () => {
        if (startIndex < sliderSchedule.length - 2) {
            setStartIndex(startIndex + 3)
        }
    }

    const handleTimeSlotChange = (timeSlot, event) => {
        event.stopPropagation()
        setSelectedTimeSlot(prevTimeSlot =>
            prevTimeSlot === timeSlot ? '' : timeSlot
        )
    }

    const isTimeSlotSelected = timeSlot => {
        return selectedTimeSlot === timeSlot
    }

    const findNextOpenDay = schedule => {
        // Init
        let today = new Date()
        let tomorrow = new Date(today)
        tomorrow.setDate(today.getDate() + 1)

        let lastSalesDate = schedule[0].day ? new Date(schedule[0].globalOrder.distribution_date) : tomorrow
        let lastSalesDateIndex = lastSalesDate.getDay()

        let nextSaleDay = locale?.distributions.find(d => d['day'] > lastSalesDateIndex)
        if (!nextSaleDay) {
            nextSaleDay = locale?.distributions.slice().sort((a, b) => a.day - b.day)[0]
        }

        let nextSaleDate = new Date(lastSalesDate)
        let dayShift = nextSaleDay && (nextSaleDay.day + 7 - lastSalesDateIndex) % 7
        if (dayShift === 0) {
            dayShift = 7
        }
        nextSaleDate.setDate(lastSalesDate.getDate() + dayShift)

        let referenceSalesDay = locale?.distributions.find(d => d['day'] === nextSaleDate.getDay())

        return { day: `${semaine[nextSaleDate.getDay()]} ${nextSaleDate.getDate()} ${mois[nextSaleDate.getMonth()]}`, open: referenceSalesDay ? `${referenceSalesDay['start'].replace(":00", "h").replace(":", "h")}-${referenceSalesDay['end'].replace(":00", "h").replace(":", "h")}` : "-", mention: true }
    }

    const calcTimeRemaining = date => {
        let preparationStart = new Date(date.globalOrder.preparation_date)
        preparationStart.setMilliseconds(0)
        preparationStart.setMinutes(0)
        preparationStart.setSeconds(0)

        let timeNow = Date.now()

        let timeRemaining = (preparationStart.getTime() - timeNow) / (1000 * 60 * 60)

        return Math.max(Math.floor(timeRemaining), 0)
    }

    useEffect(() => {
        let baseSchedule = []
        let holidaySchedule = []
        let normalSchedule = []

        if (localeSchedule && localeSchedule.length > 0) {
            baseSchedule = [...localeSchedule]

            baseSchedule.forEach(schedule => {
                let salesDate = new Date(schedule.globalOrder.distribution_date)
                // 22 dec 00:01AM
                let beginHoliday = new Date(1703199660000)
                // 31 dec 11:59PM
                let endHoliday = new Date(1704063540000)

                if (salesDate > beginHoliday && salesDate < endHoliday) {
                    holidaySchedule.push(schedule)
                }
                else {
                    normalSchedule.push(schedule)
                }
            })

            normalSchedule.push(findNextOpenDay(localeSchedule.slice(-1)))
        }
        else {
            if (locale?.launch_date && new Date(locale.launch_date) > Date.now()) {
                let launchDate = new Date(locale.launch_date)
                let printDate = { day: `${semaine[launchDate.getDay()]} ${launchDate.getDate()} ${mois[launchDate.getMonth()]}`, open: 'Ouverture prochainement' }
                if (locale?.distributions.filter(i => i.day === launchDate.getDay()).length > 0) {
                    normalSchedule.push(printDate)
                }
                else {
                    normalSchedule.push({ day: "C'est pour bientôt!", open: 'Ouverture prochainement' })
                }
            }
            else {
                normalSchedule.push(findNextOpenDay([{ globalOrder: { distribution_date: Date.now() } }]))
            }
        }

        setSliderSchedule(normalSchedule)
        setSliderHolidaySchedule(holidaySchedule)
    }, [])


    return (
        <div className={Styles.superSliderContainer}>
            {sliderSchedule.length > 0 &&
                <div className={Styles.sliderContainer}>
                    {sliderSchedule && sliderSchedule.length > 3 && startIndex > 0 ?
                        <div onClick={handlePrev}>
                            <Image
                                src={ChevronLeft}
                                className={Styles.chevronIcon}
                            />
                        </div>
                        : sliderSchedule && sliderSchedule.length > 3 && startIndex === 0 ?
                            <div style={{ opacity: 0.3 }}>
                                <Image
                                    src={ChevronLeft}
                                    className={Styles.chevronIcon}
                                />
                            </div>
                            :
                            <div style={{ opacity: 0 }}>
                                <Image
                                    src={ChevronLeft}
                                    className={Styles.chevronIcon}
                                />
                            </div>
                    }

                    <div className={Styles.slider} style={{ justifyContent: sliderSchedule.length < 3 ? 'center' : 'flex-start' }}>
                        {sliderSchedule
                            .slice(startIndex, startIndex + 3)
                            .map((date, index) => {
                                return (
                                    <div
                                        className={Styles.slide}
                                        style={window.innerWidth > 1024 ? { width: `${Math.max(33, 100 / sliderSchedule.length)}%` } : { width: '100%' }}
                                        key={index}
                                    >
                                        {showHours &&
                                            <div className={Styles.date}>
                                                {date.day}
                                            </div>
                                        }
                                        {date !== sliderSchedule.slice(-1)[0] ?
                                            <div
                                                className={`${Styles.selection} ${isTimeSlotSelected(date)
                                                    ? Styles.selected
                                                    : ''
                                                    }`}
                                                onClick={event => {
                                                    if (selectedLocale === locale && scheduleSelected === date) {
                                                        zoomInLocaleStaticMap(null)
                                                        setSelectedLocale(null)
                                                        setSelectedTimeSlot(null)
                                                        setStartMarket(false)
                                                        return
                                                    }

                                                    if (setSelectedLocale && zoomInLocaleStaticMap) {
                                                        setSelectedLocale(locale)
                                                        zoomInLocaleStaticMap(locale)
                                                    }

                                                    handleTimeSlotChange(date, event)

                                                    if (isTimeSlotSelected(date)) {
                                                        setStartMarket(false)
                                                    } else {
                                                        setStartMarket(true)
                                                    }

                                                    setScheduleSelected(date)
                                                }}
                                            >
                                                <Checkbox
                                                    icon={<RadioButtonUncheckedIcon />}
                                                    sx={{
                                                        color: 'black',
                                                        '&.Mui-checked': {
                                                            color: 'black',
                                                        },
                                                    }}
                                                    checked={isTimeSlotSelected(date)}
                                                    checkedIcon={
                                                        <RadioButtonCheckedIcon />
                                                    }
                                                />
                                                {showHours ?
                                                    <p className={Styles.timeSlot}>
                                                        {date.time}
                                                    </p>
                                                    :
                                                    <div className={Styles.date} style={{ margin: '0' }}>
                                                        {date.day}
                                                    </div>
                                                }
                                            </div>
                                            :
                                            <div className={Styles.greyedOut}>
                                                <div
                                                    className={Styles.selection}
                                                >
                                                    <Checkbox
                                                        icon={<RadioButtonUncheckedIcon />}
                                                        sx={{
                                                            color: 'black',
                                                            '&.Mui-checked': {
                                                                color: 'black',
                                                            },
                                                        }}
                                                        checkedIcon={
                                                            <RadioButtonCheckedIcon />
                                                        }
                                                    />
                                                    {showHours ?
                                                        <p className={Styles.timeSlot}>
                                                            {date.open}
                                                        </p>
                                                        :
                                                        <div className={Styles.date} style={{ margin: '0' }}>
                                                            {date.day}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {date.mention ?
                                            <div className={Styles.subSlide} style={sliderSchedule.length < 2 && (locale.new || new Date(locale.launch_date) > Date.now()) && !locale.company_domain ? { display: 'none' } : {}}>
                                                <p className={Styles.mentionGray}>Ouvre bientôt</p>
                                            </div>
                                            :
                                            showHours &&
                                                (date === sliderSchedule[0] && !date.open && calcTimeRemaining(date) < 48) ?
                                                <div className={Styles.subSlide} style={sliderSchedule.length < 2 && (locale.new || new Date(locale.launch_date) > Date.now()) && !locale.company_domain ? { display: 'none' } : {}}>
                                                    {calcTimeRemaining(date) < 1 ?
                                                        <p className={Styles.mention}>Dernières minutes pour commander</p>
                                                        :
                                                        <p className={Styles.mention}>Clôture dans {calcTimeRemaining(date).toString()}h</p>
                                                    }
                                                </div>
                                                :
                                                <div className={Styles.subSlide} style={sliderSchedule.length < 2 && (locale.new || new Date(locale.launch_date) > Date.now()) && !locale.company_domain ? { display: 'none' } : {}}>
                                                    <p className={Styles.mentionTransp}>Dernières minutes pour commander</p>
                                                </div>
                                        }
                                    </div>
                                )
                            })}
                    </div>

                    {sliderSchedule && sliderSchedule.length > 3 && startIndex < sliderSchedule.length - 3 ?
                        <div onClick={handleNext}>
                            <Image
                                src={ChevronRight}
                                className={Styles.chevronIcon}
                            />
                        </div>
                        : sliderSchedule && sliderSchedule.length > 3 && startIndex >= sliderSchedule.length - 3 ?
                            <div style={{ opacity: 0.3 }}>
                                <Image
                                    src={ChevronRight}
                                    className={Styles.chevronIcon}
                                />
                            </div>
                            :
                            <div style={{ opacity: 0 }}>
                                <Image
                                    src={ChevronRight}
                                    className={Styles.chevronIcon}
                                />
                            </div>
                    }
                </div>
            }
            {/* {(locale.new || new Date(locale.launch_date) > Date.now()) && !locale.company_domain && !currentUser.loggedin &&
                <div className={Styles.signIn}>
                    <Link
                        to={
                            '/login'
                        }
                    >
                        <span>S'inscrire / Se connecter </span>
                    </Link>
                </div>
            } */}
            {!showHours && scheduleSelected &&
                <p className={Styles.greenMention}>{`Créneau de livraison à choisir dans l'étape du règlement de la commande`}</p>
            }
            {startMarket && (
                <div
                    className={Styles.startMarket}
                    onClick={() => handleNavigate(scheduleSelected)}
                >
                    Commencer mon marché
                </div>
            )
            }
        </div>
    )
}

export default DateSlider
